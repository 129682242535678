import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import ServiceHeader from "../../components/Services/ServiceHeader"
import WebAccents from "../../components/SingleService/Web/WebAccents"
import WebTechnologies from "../../components/SingleService/Web/WebTechnologies"
import WebAgile from "../../components/SingleService/Web/WebAgile"
import LatestProjects from "../../components/UiUxDesign/uiUxLatestProject"
import WorkTogetherSection from "../../components/Services/WorkTogetherSection"
import dataForWeb from "../../information/Services/dataForWeb"
import { mainUrl } from "../../js/config"
import "../../components/SingleService/Web/WebRoadmap.scss"

export default function Web() {
  const { service, header, technologies, accents, agile, projects } = dataForWeb
  return (
    <section className="web">
      <SEO
        title="Web Development Services for Your Business"
        description="OSsystem offers custom web application development. End-to-end web development service to grow your business. Book a consultation now."
        canonical={`${mainUrl}/services/web/`}
      />
      <Layout showFormPoint={100}>
        <ServiceHeader title={header.title} BG={header.background} />
        <WebAccents accents={accents} />
        <WebTechnologies id="qwerty" stack={technologies} />
        <WebAgile agile={agile} />
        <LatestProjects />
        <WorkTogetherSection />
      </Layout>
    </section>
  )
}
