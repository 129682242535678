import { WebBG } from "../../images/Services/Backgrounds"

import {
  AgileArrow,
  AgileAssess,
  AgileDevelop,
  AgileExplore,
  AngularJs,
  ArrowLeft,
  ArrowRight,
  Bootstrap,
  ExtJs,
  Html,
  JS,
  Laravel,
  NodeJs,
  Php,
  React,
  Sass,
  Symfony,
  UnionLeft,
  UnionRight,
} from "../../images/Services/WebTechnologies/"

const dataForWeb = {
  service: "web",
  header: {
    title: "Web App Development",
    background: WebBG,
  },
  technologies: [
    [
      {
        id: "10",
        alt: "NodeJS",
        url: NodeJs,
      },
      {
        id: "15",
        alt: "ReactJS",
        url: React,
      },
      {
        id: "20",
        alt: "PHP",
        url: Php,
      },
      {
        id: "25",
        alt: "AngularJS",
        url: AngularJs,
      },
      {
        id: "30",
        alt: "Sass",
        url: Sass,
      },
      {
        id: "35",
        alt: "Laravel",
        url: Laravel,
      },
    ],
    [
      {
        id: "40",
        alt: "HTML5",
        url: Html,
      },
      {
        id: "45",
        alt: "JS",
        url: JS,
      },
      {
        id: "50",
        alt: "ExtJS",
        url: ExtJs,
      },
      {
        id: "55",
        alt: "Bootstrap",
        url: Bootstrap,
      },
      {
        id: "60",
        alt: "Symfony",
        url: Symfony,
      },
    ],
  ],
  accents: [
    {
      id: 1,
      title: "Advantages of Web Applications",
      arrow: ArrowRight,
      frame: UnionLeft,
      articles: {
        texts: [
          "<span><strong>Improved</strong></span> interoperability",
          "<span><strong>Cost effective</strong></span> development",
          "<span><strong>Easily</strong></span> customizable",
          "<span><strong>Accessible</strong></span> for a range of devices",
        ],
      },
    },
    {
      id: 2,
      articles: {
        texts: [
          "OSSystem provides end-to-end web development service based on your particular business challenges and goals. Such process normally includes a comprehensive analysis of customer pains, his target user segment, their key activities and expectations.",
        ],
      },
    },
    {
      id: 3,
      title: "Advantages of Web developing with OS-system",
      arrow: ArrowLeft,
      frame: UnionRight,
      articles: {
        texts: [
          "<span>You <strong>focus</strong> on your business</span> while we do routine coding",
          "<span><strong>Get more</strong></span> for the same money",
          "<span>Our technical skills are <strong>always</strong></span><strong> in tune</strong> with current trends",
          "<span><strong>On-time</strong></span> delivery",
        ],
      },
    },
  ],
  agile: [
    {
      id: 1,
      title: "Agile Web Development at OS-system",
      agileCycles: [
        {
          id: 1,
          images: [AgileExplore, AgileArrow, AgileAssess],
          titles: ["Explore", "Assess"],
        },
        {
          id: 2,
          images: [AgileDevelop],
          titles: ["Design", "Develop", "QA", "Deploy", "Profit"],
        },
      ],
    },
  ],
  projects: [
    {
      id: "210",
    },
    {
      id: "230",
    },
  ],
}

export default dataForWeb
