import React from "react"

const WebTechnologies = ({ stack }) => {
  return (
    <div className="web-tech search">
      <div className="container">
        <div className="xs-concat-rows row">
          {stack.map((list, i) => (
            <ul key={i} className="web-tech-list">
              {list.map(image => {
                const { id, alt, url } = image
                return (
                  <li key={id} className="web-tech-list_item">
                    <img src={url} alt={alt} />
                  </li>
                )
              })}
            </ul>
          ))}
        </div>
      </div>
    </div>
  )
}

export default WebTechnologies
