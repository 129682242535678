import React from "react"

const WebAccents = ({ accents }) => {
  return (
    <div className="container search">
      <div className="web-description">
        {accents.map(accent =>
          accent.title ? (
            <div key={accent.id} className="row-flex">
              <div className="col-lg-12 col-md-12 col-sm-4 col-xs-5 accent-title">
                <h3 className="web-description-title">{accent.title}</h3>
              </div>
              <div className="col-flex col-lg-12 col-md-12 col-sm-6 col-xs-7">
                <ul className="web-description-list">
                  {accent.articles.texts.map((text, idx, arr) => (
                    <li key={idx} className="web-description-list_item">
                      <p dangerouslySetInnerHTML={{ __html: text }} />
                      {arr.length - 1 - idx ? (
                        <img src={accent.arrow} alt="-" />
                      ) : null}
                    </li>
                  ))}
                </ul>
                <img src={accent.frame} />
              </div>
            </div>
          ) : (
            <div key={accent.id} className="row-flex">
              <div className="col-flex col-lg-12 col-md-12 col-sm-10 col-xs-8">
                <ul className="web-description-list">
                  {accent.articles.texts.map((text, idx) => (
                    <li key={idx} className="web-description-list_item">
                      <p dangerouslySetInnerHTML={{ __html: text }} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default WebAccents
