import React from "react"

const WebAgile = ({ agile }) => {
  return (
    <div className="container search">
      <div className="web-agile">
        {agile.map(agile => (
          <div key={agile.id} className="row-flex">
            <div className="col-lg-5 col-md-4 col-sm-5 col-xs-12">
              <h3 className="web-agile-title">{agile.title}</h3>
            </div>
            <div className="col-flex col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="web-agile-cycles">
                {agile.agileCycles.map(cycle => (
                  <div key={cycle.id} className="web-agile-cycles_item">
                    {cycle.images.map((image, idx) => (
                      <img
                        key={idx}
                        className="web-agile-cycles_item_image"
                        src={image}
                        alt="cycle"
                      />
                    ))}
                    {cycle.titles.map((title, idx) => (
                      <span key={idx} className="web-agile-cycles_item_name">
                        {title}
                      </span>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default WebAgile
